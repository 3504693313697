import React from "react"
import TemplatePageGuide from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide79/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
    <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Cómo mejorar el proceso de onboarding en los bancos
        [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/proceso-onboarding-bancos/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Optimiza el proceso de onboarding en tu banco y mejora la experiencia del cliente desde el primer contacto. Descubre estrategias efectivas."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo mejorar el proceso de onboarding en los bancos"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/proceso-onboarding-bancos/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia79.jpg`}
      />
      <meta
        property="og:description"
        content="Optimiza el proceso de onboarding en tu banco y mejora la experiencia del cliente desde el primer contacto. Descubre estrategias efectivas."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Banca y finanzas"
      location={location}
      typeGuide="guide79"
      clickHereHref="https://beexcc.com/blog/"
      data={data}
    />
  </div>
)

export default IndexPage
