import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide79/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide79/image/img.png"
import slider1 from "@components/pageGuide/guides/guide79/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide79/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide79/image/silder3.png"


const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"    >
      Política de Privacidad.
    </a>
  </span>
)

const data = {
  start: {
    support: "Guía | Banca y finanzas ",
    title: <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo mejorar el proceso de onboarding en los bancos
    </h1>,
    description: <p className="text-h2-ebook guide__description">
      El proceso de onboarding es clave para facilitar la integración de nuevos clientes. Simplifica los pasos para reducir tiempos y mejorar la experiencia. Digitaliza la recolección de datos para agilizar la validación de identidad.
    </p>,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-9.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "El papel de la ciberseguridad en el onboarding.",
      },
      {
        key: 2,
        text: " Experiencia del usuario (UX) en el onboarding."
      },
      {
        key: 3,
        text: "Herramientas tecnológicas que potencian el onboarding.",
      },
      {
        key: 4,
        text: " Principales retos del onboarding bancario.",
      },
      {
        key: 5,
        text: "¿Por qué es clave mejorar el onboarding en los bancos?.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },
  description: {
    cards: [
      {
        text: <p>
          Recuerda que, con esta guía, podrás conocer cómo optimizar cada fase, desde el registro hasta la activación de servicios. Asegura una comunicación clara para guiar a los usuarios en cada paso.
          <br /><br />
          Por último, accede a más detalles, ejemplos prácticos y herramientas recomendadas descargándola. Mejora la eficiencia y el cumplimiento en tu proceso de onboarding
        </p>,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
